@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@700&family=Libre+Baskerville:ital@0;1&display=swap");

html,
body,
#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Libre Baskerville", "League Spartan", serif, sans-serif;
}
